import React from 'react';
import {createAccountNavigationData} from '../navigationData';
import DownloadButton from '../../DownloadButton';

import * as s from './s.module.css'

export default [{
    title: 'Create Account',
    subtitle: "1. Download the application installer, double-click to install",
    blueIndexSubtitle: true,
    customComponent: () => <DownloadButton className={s.downloadBtn} showOnDesktopOnly />,
    navigationreferenceid: `#${createAccountNavigationData[0].id}`,
}, {
    subtitle: `2. Launch Virtue Poker and click Sign up`,
    blueIndexSubtitle: true,
    img: '1-login',
    navigationreferenceid: `#${createAccountNavigationData[1].id}`,
    imgOverlay: true,
}, {
    subtitle: '3. Create your account',
    blueIndexSubtitle: true,
    img: '2-register',
    navigationreferenceid: `#${createAccountNavigationData[2].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Create and confirm your password',
    img: '3-create-confirm',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[0].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Enter your personal information',
    img: '4-fill-out',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[1].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Enter your display name',
    img: '5-set-display-name',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[2].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Enter your email verification code',
    img: '6-verification',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[3].id}`,
    imgOverlay: true,
}, {
    subtitle: 'You have completed the first step. Click OK and log in',
    img: '7-confirmation',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[4].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Link your wallet',
    img: '8-linkwallet',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[5].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Paste your Ethereum address if you already have a wallet',
    img: '9-pasteaddress',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[6].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Click Start wallet creation if you need to create a wallet',
    img: '10-create',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[7].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Secure your wallet',
    img: '11-securewallet',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[8].id}`,
    imgOverlay: true,
}, {
    subtitle: 'Your registration is complete, make sure to back-up your recovery phrase',
    img: '12-registered',
    navigationreferenceid: `#${createAccountNavigationData[2].subtitles[9].id}`,
    imgOverlay: true,
}];