import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import CreateAccountPage from '../../components/GettingStarted/createAccountPage';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Create Account" />
    <CreateAccountPage location={location}/>
  </Layout>
)

export default IndexPage
